import React, { useState } from 'react';
import axios from 'axios';
import './App.css';

function Chat() {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const messagesEndRef = React.useRef(null);

  React.useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const sendMessage = async (event) => {
    event.preventDefault();
    if (input.trim() === '') return; // Prevent sending empty messages
    // Commenting out the server response until the server is ready
    const response = await fetch('https://tas2ox4v4j.execute-api.eu-west-1.amazonaws.com/answerMessage', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ text: input })
    });
    const data = await response.json();
    setMessages([...messages, { text: input, from: 'user' }, { text: data, from: 'server' }]);

    //const randomReply = "Random reply message"; // This will be replaced with server response
    //setMessages([...messages, { text: input, from: 'user' }, { text: randomReply, from: 'server' }]);
    setInput('');
  };

  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) { /* You can add code here to load more messages if you have pagination implemented on your server */ }
  };

  return (
    <div className="chat">
      <div className="messages" onScroll={handleScroll}>
        {messages.map((message, index) => (
          <div key={index} className={`message ${message.from}`}>
            {message.text}
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
      <form onSubmit={sendMessage}>
        <input
          className="message input"
          value={input}
          onChange={e => setInput(e.target.value)}
          type="text"
          placeholder="Type a message..."
        />
      </form>
    </div>
  );
}

function App() {
  return <Chat />;
}

export default App;